import { find, get, map, uniqBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, LabeledIconInput, Loading, Popup, Row, SearchIcon, Theme, themeOptions } from '@commonsku/styles';

import { getFullItem } from '../selectors';
import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import { createFetchProduct } from '../actions/product';
import { createGlobalStyle } from './helpers';
import { fetchParts, getPartsFromSkus, loadProductSkus, reloadInventory } from '../helpers/ps_sku';
import CheckInventoryLevelTable from './CheckInventoryLevelTable';

export const GlobalStyle = createGlobalStyle();

class CheckPSInventoryLevelPopup extends Component {

  constructor(props) {
    super(props);

    const product_sku = find(get(props.product, 'skus', []), {product_sku_id: props.product_sku_id});

    this.state = {
      loading: false,
      color: '',
      size: '',
      item_levels: this.props.item.levels ? this.props.item.levels.results : null,
      skus: product_sku ? [product_sku.sku]: [],
      search_query: '',
    };
  }

  componentDidMount() {
    const { product, loadProduct } = this.props;
    if (!product) {
      loadProduct();
    } else {
      this.fetchParts();
      this.reloadInventory();
    }
  }

  componentDidUpdate(prevProps) {
    const { product_id, product, loadProduct } = this.props;
    if (product_id !== prevProps.product_id || product != prevProps.product) {
      if (!product) {
        loadProduct();
      } else {
        this.fetchParts(0);
        this.reloadInventory();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.product_id !== nextProps.product_id) {
      if (!nextProps.product) {
        nextProps.loadProduct();
      } else {
        this.fetchParts();
        this.reloadInventory();
      }
    }
  }

  async fetchParts(offset) {
    const { product, currency_id, } = this.props;
    const state = await fetchParts(product, currency_id, this.state.skus, offset);
    this.setState(state);
  }

  async reloadInventory() {
    const { product } = this.props;
    const state = await reloadInventory(product.ext_product_id);
    this.setState(state);
  }

  async loadProductSkuIds(skus) {
    const product_skus = await this.loadProductSkus(skus);
    return map(product_skus, (sku) => get(sku, ['product_sku_id']));
  }

  async loadProductSkus(skus) {
    const { product, loadProduct } = this.props;
    const result = await loadProductSkus(product, loadProduct, skus);
    return result;
  }

  renderLoading() {
    return (
      <Col xs style={{ textAlign: 'center' }}>
        <Loading />
      </Col>
    );
  }

  render() {
    const { product, index, currency_id = "USD", onClosePopup } = this.props;
    const { loading, skus, inventory={}, inventoryLoaded=false, search_query, } = this.state;

    const parts = getPartsFromSkus(skus, currency_id, inventory, inventoryLoaded);

    const filterSearchByPart = (p) => {
      if (!search_query) { return true; }
      const query = search_query.toLowerCase();
      return (p.color || '').toLowerCase().includes(query)
            || (p.size || '').toLowerCase().includes(query)
            || (p.sku || '').toLowerCase().includes(query)
            || (p.inventory || '').toLowerCase().includes(query)
            || (p.description || '').toLowerCase().includes(query);
    };
    const item_levels = map(
      parts.filter(filterSearchByPart),
      v => ({
        part_id: v.sku,
        description: v.description,
        color: v.color,
        size: v.size,
        quantity_available: v.inventory.split(' ')[0].trim(),
      })
    );

    const title = loading ? 'Fetching Inventory Level' : !item_levels ? 'No Inventory Level Available' : 'View Inventory Level';

    return (
      <Theme theme={themeOptions}>
        <GlobalStyle theme={themeOptions} />
        <Popup
          closeOnEsc
          title={title}
          onClose={onClosePopup}
          zIndex={BASE_ZINDEX + index}
          overlayZIndex={BASE_ZINDEX + index}
          controls={<>
            <LabeledIconInput
              label=''
              Icon={<SearchIcon color='#D0D7DC' style={{ marginTop: '30%', }} />}
              className='sku-options-search-query'
              placeholder="Filter"
              value={search_query}
              onChange={e => this.setState({ search_query: e.target.value })}
              style={{
                margin: 0,
                marginRight: 10,
                width: 225,
                height: 54,
              }}
              iconColor='#D0D7DC'
              iconLabelStyles={{ height: '100%', background: 'none' }}
              containerStyle={{ display: 'inline-block', }}
            />
            <Button variant={'cta'} onClick={onClosePopup}>Done</Button>
          </>}
        >
          <Row>
            {(!product || loading) ? this.renderLoading() :
              <CheckInventoryLevelTable item_levels={item_levels} />
            }
          </Row>
        </Popup>
      </Theme>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  return {
    item: getFullItem(state, { item_id: ownProps.item_id }),
    product: ownProps.product || state.entities.products[ownProps.product_id],
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    loadProduct: (data={}) => dispatch(createFetchProduct(ownProps.product_id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPSInventoryLevelPopup);
